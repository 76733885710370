// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-address-form-js": () => import("./../src/pages/AddressForm.js" /* webpackChunkName: "component---src-pages-address-form-js" */),
  "component---src-pages-checkout-js": () => import("./../src/pages/Checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-orig-js": () => import("./../src/pages/index-orig.js" /* webpackChunkName: "component---src-pages-index-orig-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-landing-page-js": () => import("./../src/pages/landing/LandingPage.js" /* webpackChunkName: "component---src-pages-landing-landing-page-js" */),
  "component---src-pages-landing-sections-product-section-js": () => import("./../src/pages/landing/Sections/ProductSection.js" /* webpackChunkName: "component---src-pages-landing-sections-product-section-js" */),
  "component---src-pages-landing-sections-team-section-js": () => import("./../src/pages/landing/Sections/TeamSection.js" /* webpackChunkName: "component---src-pages-landing-sections-team-section-js" */),
  "component---src-pages-landing-sections-work-section-js": () => import("./../src/pages/landing/Sections/WorkSection.js" /* webpackChunkName: "component---src-pages-landing-sections-work-section-js" */),
  "component---src-pages-landing-sections-yk-listing-section-js": () => import("./../src/pages/landing/Sections/YKListingSection.js" /* webpackChunkName: "component---src-pages-landing-sections-yk-listing-section-js" */),
  "component---src-pages-landing-2-index-js": () => import("./../src/pages/landing2/index.js" /* webpackChunkName: "component---src-pages-landing-2-index-js" */),
  "component---src-pages-landing-2-sections-team-section-js": () => import("./../src/pages/landing2/Sections/TeamSection.js" /* webpackChunkName: "component---src-pages-landing-2-sections-team-section-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-payment-form-js": () => import("./../src/pages/PaymentForm.js" /* webpackChunkName: "component---src-pages-payment-form-js" */),
  "component---src-pages-review-js": () => import("./../src/pages/Review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-pages-selection-index-js": () => import("./../src/pages/selection/index.js" /* webpackChunkName: "component---src-pages-selection-index-js" */),
  "component---src-pages-selection-sections-product-section-js": () => import("./../src/pages/selection/Sections/ProductSection.js" /* webpackChunkName: "component---src-pages-selection-sections-product-section-js" */),
  "component---src-pages-selection-sections-team-section-js": () => import("./../src/pages/selection/Sections/TeamSection.js" /* webpackChunkName: "component---src-pages-selection-sections-team-section-js" */),
  "component---src-pages-selection-sections-work-section-js": () => import("./../src/pages/selection/Sections/WorkSection.js" /* webpackChunkName: "component---src-pages-selection-sections-work-section-js" */),
  "component---src-pages-selection-sections-yk-listing-section-js": () => import("./../src/pages/selection/Sections/YKListingSection.js" /* webpackChunkName: "component---src-pages-selection-sections-yk-listing-section-js" */),
  "component---src-pages-y-kblachford-index-js": () => import("./../src/pages/YKblachford/index.js" /* webpackChunkName: "component---src-pages-y-kblachford-index-js" */),
  "component---src-pages-y-kregular-index-js": () => import("./../src/pages/YKregular/index.js" /* webpackChunkName: "component---src-pages-y-kregular-index-js" */)
}

