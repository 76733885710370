import React, { useEffect } from "react"
// import useFirebase from "components/useFirebase"
import axios from "axios"

const maxUsers = 4

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

// const firebase = useFirebase()

const initialState = {
  maxUsers: maxUsers,
  activeStep: 0,
  headcount: 0,
  guests: Array.from(Array(maxUsers), () => {}),
  submitted: false,
}

// useEffect(() => {
//   if (!firebase) return
//   firebase
//     .database()
//     .ref("POCtest/staging")
//     .set(state)
// }, [firebase])

// const writeDB = data => {
//   firebase
//     .database()
//     .ref("POCtest/staging")
//     .set(data)
// }

function reducer(state, action) {
  switch (action.type) {
    case "RESET": {
      return { ...initialState, entryTimestamp: new Date().getTime() }
    }
    case "PREV_STEP": {
      return {
        ...state,
        activeStep: state.activeStep - 1,
      }
    }
    case "NEXT_STEP": {
      // console.log("active: " + state.activeStep)
      // writeDB(state)
      return {
        ...state,
        activeStep: state.activeStep + 1,
      }
    }
    case "UPDATE_HEADCOUNT": {
      return {
        ...state,
        headcount: action.value,
      }
    }
    case "SET": {
      return {
        ...state,
        guests: state.guests.map((obj, i) => {
          if (i === action.index) {
            return { ...obj, [action.field]: action.value }
          }
          return obj
        }),
      }
    }
    case "SUBMIT": {
      const output = {
        ...state,
        activeStep: state.activeStep + 1,
        submitted: true,
        submitTimestamp: new Date().getTime(),
      }

      var xhr = new XMLHttpRequest()

      xhr.open(
        "post",
        "https://script.google.com/macros/s/AKfycbx-8vWKssP1DS09nD1OAjIWGcLuhW-pwb4MrXYkHypq7N3bYXQ/exec"
      )

      xhr.send(
        JSON.stringify(output)
        // JSON.stringify(output, function replacer(key, value) {
        //   return value
        // })
      )

      return output
    }
    default:
      return { state }
  }
}

const GlobalContextProvider = ({ children }) => {
  // const firebase = useFirebase()
  const [state, dispatch] = React.useReducer(reducer, initialState)
  // useEffect(() => {
  //   if (!firebase || state.headcount === 0) return
  //   firebase
  //     .database()
  //     .ref("POCtest/staging")
  //     .set(state)
  // }, [firebase])
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
